import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const DivPersonMobile = ({ data, srcImage }) => {
    console.log(data)

    return (
        <div className="div-person-mobile">
            <StaticImage
                src="../images/person.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt={data.globalJson.info.name}
            />
            <div className="div-info">
                <div className="block margin-top-minus">
                    <p className="name">
                        {data.globalJson.info.name}
                    </p>
                    <p className="info">
                        {data.globalJson.info.department}
                    </p>
                    <a href="https://laura-costa.pontourbano.pt/vcard.vcf" download="laura_costa" className='add-contact-button'>
                        <StaticImage
                            src="../images/icon-download.png"
                            loading="eager"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt=""
                            style={{ height: '100%', width: 'auto', marginTop: '2vh' }}
                        />
                    </a>
                </div>

            </div>
        </div>
    )
}

export default DivPersonMobile
